.link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .5s;
}

.link-underline-black {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fbbf24, #fbbf24)
}

.link-underline:hover {
    background-size: 100% 3px;
    background-position: 0 100%;
}

/* active tab have permenent yellow underline which appear on Hover event */
.link-underline-active {
    background-size: 100% 3px;
    background-position: 0 100%;
}