* {
    scroll-behavior: smooth;
}

.bg-slate-200 {
    background-color: #8EC5FC;
}

.gradient-background {
    background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@tailwind base;
@tailwind components;
@tailwind utilities;