.url-input input {
	border: 2px solid #fbbf24;
	border-radius: 15px;
}

.url-input .MuiFilledInput-root::before,
.url-input .MuiFilledInput-root::after,
.url-input .MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before {
	border-bottom: 1px solid transparent;
	border-radius: 15px;
}

.url-input .MuiInputLabel-root,
.url-input.Mui-focused {
	color: #d79b00 !important;
}

.url-input .MuiFilledInput-root {
	border-radius: 15px;
	background: transparent;
}

.scan-btn {
	font-size: 16px;
	padding: 10px 30px;
	border: 0;
	cursor: pointer;
	color: #fff;
	font-weight: bolder;
	border-radius: 10px;
	display: inline-block;
	overflow: hidden;
	transition: all 0.4s cubic-bezier(.86, .01, .15, .99);
}

.scan-btn::before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0;
}

.scan-btn.gradient:hover {
	box-shadow: 0 5px 35px rgba(0, 0, 0, 0.6);
	transform: translateY(-5px);
}

.scan-btn {
	background: #d79b00;
	transform: perspective(1px) translateZ(0);
	transition: 0.4s;
}

.scan-btn::before {
	background: #b08619;
	clip-path: polygon(50% 0, 50% 0, 50% 50%, 50% 100%, 50% 100%, 50% 50%);
	opacity: 1;
	transition: 0.4s;
}

.scan-btn:hover::before {
	clip-path: polygon(25% -70%, 75% -70%, 120% 50%, 75% 170%, 25% 170%, -20% 50%);
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
}

.scan-btn:hover {
	box-shadow: 0 0 0 10px rgba(250, 250, 250, 0.1)
}

@media (max-width: 768px) {

}